import * as React from "react"

const RestoGuideIconKz = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 47 51"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
    >
        <path d="M28.7459 35.9916L40.7757 29.3066C42.0514 28.5977 43.6581 28.9953 44.4558 30.2174V30.2174C45.2577 31.4459 44.9677 33.086 43.793 33.9648L33.0029 42.0382C29.8579 44.3838 25.9506 45.4711 22.046 45.0871L17.096 44.6002C16.3806 44.5299 15.6644 44.7254 15.084 45.1496L10.6267 48.4068C10.1257 48.7729 9.42587 48.6842 9.03211 48.2045L2.38688 40.1093C1.93964 39.5645 2.07216 38.7508 2.66916 38.3761L6.44205 36.0077C7.20866 35.5264 7.66434 34.7 7.96366 33.8458C8.85627 31.2985 11.4134 29.7474 14.354 29.7474L27.0171 30.6455C28.9151 30.8548 30.21 32.0837 30.0367 33.8458C29.9368 34.862 29.3181 35.73 28.4376 36.17C27.9528 36.4123 27.3962 36.437 26.8546 36.4549L19.456 36.699" stroke="#2A2D33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.0028 25.4854V4.05014C21.9078 2.7871 19.4593 2.06152 16.8432 2.06152C14.2271 2.06152 11.7786 2.7871 9.68359 4.05014V25.4854" stroke="#2A2D33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.0028 25.5213C21.9078 24.2583 19.4593 23.5327 16.8432 23.5327C14.2271 23.5327 11.7786 24.2583 9.68359 25.5213" stroke="#2A2D33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M38.3231 25.4854V4.05014C36.2281 2.7871 33.7796 2.06152 31.1635 2.06152C28.5474 2.06152 26.0989 2.7871 24.0039 4.05014V25.4854" stroke="#2A2D33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M38.3231 25.5213C36.2281 24.2583 33.7796 23.5327 31.1635 23.5327C28.5474 23.5327 26.0989 24.2583 24.0039 25.5213" stroke="#2A2D33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default RestoGuideIconKz
