import * as React from "react"

const SupportIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M47.452 43.778H21.16a4.611 4.611 0 0 0-4.611 4.61v0A4.611 4.611 0 0 0 21.158 53h26.294"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M47.452 43.778V11H21.548a5 5 0 0 0-5 5v32.46"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M35.246 31.98c2.77-1.316 4.7-4.266 4.7-7.695 0-3.483-1.992-6.473-4.834-7.757v7.802H32"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M35.106 32.068v6.182" strokeWidth={3} strokeLinecap="round" />
		<path
			d="M28.753 31.98c-2.77-1.316-4.7-4.266-4.7-7.695 0-3.483 1.992-6.473 4.834-7.757v7.802H32"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M28.893 32.068v6.182M47.446 48.355H20.95"
			strokeWidth={3}
			strokeLinecap="round"
		/>
	</svg>
)

export default SupportIcon
