import * as React from "react"

const BlogIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M44.045 22.5V17a4 4 0 0 0-4-4h-22a4 4 0 0 0-4 4v30a4 4 0 0 0 4 4h22a4 4 0 0 0 4-4v-5.5"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M14.226 22.97h29.637" strokeWidth={3} strokeLinecap="round" />
		<circle cx={20.061} cy={18.008} r={1.5} />
		<circle cx={24.561} cy={18.008} r={1.5} />
		<circle cx={29.061} cy={18.008} r={1.5} />
		<path
			d="m50.219 22.09 3.736 3.736L37.761 42.02l-5.166 1.43 1.43-5.166 16.194-16.195Z"
			strokeWidth={3}
			strokeLinejoin="round"
		/>
	</svg>
)

export default BlogIcon
