import React from "react";
import classNames from "classnames";

import ChevronRight from "../../../assets/_V2/svg/ChevronRight";

import styles from "./styles.module.scss";

import { getData } from "./data";
import AdaptiveLink from "../../../components/_V2/AdaptiveLink";
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

// Цвет айтама
enum THEME_TYPE {
	GRAY_LIGHT = "gray-light",
	WHITE = "white",
}

interface Props {
	className?: string;
	type?: string;
	theme?: THEME_TYPE;
}

export default function KnowledgeBase(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	const theme =
		props.theme === undefined ? THEME_TYPE.GRAY_LIGHT : THEME_TYPE.WHITE;

	return (
		<section
			className={classNames(styles.section, props.className, {
				[styles.sectionHorizontal]: props.type === "horizontal",
			})}
		>
			<div>
				<p className={styles.label}>{data.label}</p>

				<h2 className={classNames(styles.title,
					data?.titleClass,
					{[styles.title__kz]: localizationContext.locale === 'kz-KZ'}
				)}>
					{data.title}
				</h2>

				<p
					className={classNames(styles.description, { [styles.description__by]: localizationContext.locale !== "ru-RU" }, { [styles.description__desktop]: data?.mobileDescription })}
				>
					{data.description}
				</p>
				{data?.mobileDescription && (
					<p
						className={classNames(styles.description, { [styles.description__by]: localizationContext.locale !== "ru-RU" }, styles.description__mobile)}
					>
						{data.mobileDescription}
					</p>
				)}

			</div>

			<ul className={styles.knowledgeBase}>
				{data.items.map(item => (
					<li
						className={classNames(styles.knowledgeBaseItem__wrapper, {
							[styles.grayLight]: theme === THEME_TYPE.GRAY_LIGHT,
							[styles.white]: theme === THEME_TYPE.WHITE,
						})}
						key={item.link.href}
					>
						<AdaptiveLink
							className={styles.knowledgeBaseItem}
							href={item.link.href}
							useGatsbyLink={item.link.useGatsbyLink}
							target={item.link.target}
						>
							{item.icon}

							<span className={styles.knowledgeBaseItem__text}>
								{item.text}
								<ChevronRight className={styles.knowledgeBaseItem__textIcon} />
							</span>
						</AdaptiveLink>
					</li>
				))}
			</ul>
		</section>
	);
}
