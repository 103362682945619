import * as React from "react"

const WebinarIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M48.991 33.545v10.773c0 3.967-3.072 7.182-6.862 7.182H16.393c-3.79 0-6.863-3.215-6.863-7.182V22.772c0-3.966 3.073-7.182 6.863-7.182h13.512"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="m23.256 25.465 15.441 8.056-15.441 8.104v-16.16Z"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M40.115 17.748v6.018c0 1.598 2.713 2.894 6.06 2.894 3.348 0 6.061-1.296 6.061-2.894v-6.018m-16.355-2.227L46.175 10.5l10.295 5.02-10.294 5.021-10.295-5.02Z"
			strokeWidth={3}
			strokeLinejoin="round"
		/>
	</svg>
)

export default WebinarIcon
