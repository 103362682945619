import React from "react";
import styles from "./styles.module.scss";

import RestoGuideIcon from "./assets/RestoGuideIcon.tsx";
import RestoGuideIconKz from "./assets/RestoGuideIconKz.tsx";
import BlogIcon from "./assets/BlogIcon.tsx";
import WebinarIcon from "./assets/WebinarIcon.tsx";
import SupportIcon from "./assets/SupportIcon.tsx";
import {
	externalLinks,
	pagesLinks, pagesLinksKz,
} from "../../../pages-data/_V2/common/links.ts";
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			label: "База знаний",
			title: "Для тех, \nкто открывается",
			titleClass: styles.title__desk,
			description: (
				<>
					С&nbsp;нами открылись более 10&nbsp;000&nbsp;заведений. Мы&nbsp;собрали лучшие практики
					и&nbsp;материалы для начинающих рестораторов в&nbsp;нашей &laquo;Базе знаний&raquo;. Открывайся
					уверенно!
				</>
			),
			mobileDescription: (
				<>
					С&nbsp;нами открылись более 10 000 заведений.&nbsp;Мы собрали лучшие практики и&nbsp;материалы для
					начинающих рестораторов в&nbsp;нашей &laquo;Базе знаний&raquo;. Открывайся уверенно!
				</>
			),
			items: [
				{
					icon: <RestoGuideIconKz
						className={classNames(styles.knowledgeBaseItem__icon, styles.knowledgeBaseItem__icon__kz)} />,
					text: "Справочник\nресторатора",
					link: {
						...pagesLinks.restoGuide,
						target: "_blank",
					},
				},
				{
					icon: <BlogIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Медиа проект\n«Котёл»",
					link: {
						...pagesLinks.blog,
						target: "_blank",
					},
				},
				{
					icon: <WebinarIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Обучающие\nвебинары",
					link: {
						href: externalLinks.webinars,
						target: "_blank",
					},
				},
				{
					icon: <SupportIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Раздел\nтехподдержки",
					link: {
						href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
						useGatsbyLink: false,
						target: "_blank",
					},
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			label: "БІЛІМ ҚОРЫ",
			title: "Ашылып жатқандарға\nарналған",
			titleClass: styles.title__desk,
			description: (
				<>
					Бізбен 10 000 астам мекемелер ашылды. Біздің «Білім қорында»
					біз жаңадан бастаушы рестораторлар үшін үздік тәжірибелер мен материалдарды жинадық. Сеніммен ашылыңыз!
				</>
			),
			mobileDescription: (
				<>
					Бізбен 10 000 астам мекемелер ашылды. Біздің «Білім қорында» біз жаңадан бастаушы рестораторлар үшін
					үздік тәжірибелер мен материалдарды жинадық. Сеніммен ашылыңыз!
				</>
			),
			items: [
				{
					icon: <RestoGuideIconKz
						className={classNames(styles.knowledgeBaseItem__icon, styles.knowledgeBaseItem__icon__kz)} />,
					text: "Ресторатордың\nанықтамалығы ",
					link: {
						...pagesLinksKz.restoGuide,
						target: "_blank",
					},
				},
				{
					icon: <BlogIcon className={styles.knowledgeBaseItem__icon} />,
					text: "«Котёл»\nмеда жобасы",
					link: {
						...pagesLinksKz.blog,
						target: "_blank",
					},
				},
				{
					icon: <WebinarIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Үйрететін\nвебинарлар",
					link: {
						href: externalLinks.webinars,
						target: "_blank",
					},
				},
				{
					icon: <SupportIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Техникалық\nқолдау бөлімі",
					link: {
						href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
						useGatsbyLink: false,
						target: "_blank",
					},
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			label: "База знаний",
			title: "Для открытия нового",
			description: (
				<>
					С&nbsp;нами открылись более 10&nbsp;000 заведений в&nbsp;СНГ. Мы&nbsp;собрали лучшие практики
					и&nbsp;материалы для начинающих рестораторов в&nbsp;нашей &laquo;Базе знаний&raquo;. Запускай бизнес
					уверенно!
				</>
			),
			items: [
				{
					icon: <RestoGuideIconKz
						className={classNames(styles.knowledgeBaseItem__icon, styles.knowledgeBaseItem__icon__kz)} />,
					text: "Справочник\nресторатора",
					link: {
						...pagesLinks.restoGuide,
						target: "_blank",
					},
				},
				{
					icon: <BlogIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Медиа проект\n«Котёл»",
					link: {
						...pagesLinks.blog,
						target: "_blank",
					},
				},
				{
					icon: <WebinarIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Обучающие\nвебинары",
					link: {
						href: externalLinks.webinars,
						target: "_blank",
					},
				},
				{
					icon: <SupportIcon className={styles.knowledgeBaseItem__icon} />,
					text: "Раздел\nтехподдержки",
					link: {
						href: `${externalLinks.quickrestoRU.href}${pagesLinks.support.href.slice(1)}`,
						useGatsbyLink: false,
						target: "_blank",
					},
				},
			],
		};
	}

	return {
		label: "База знаний",
		title: "Для всех\nпервооткрывателей",
		description: (
			<>
				С&nbsp;нами открылись более 30&nbsp;тысяч&nbsp;заведений. Мы&nbsp;собрали
				лучшие практики и&nbsp;материалы для начинающих рестораторов в&nbsp;нашей
				&laquo;Базе знаний&raquo;. Открывайтесь уверенно!
			</>
		),
		items: [
			{
				icon: <RestoGuideIcon className={styles.knowledgeBaseItem__icon} />,
				text: "Гид\nресторатора",
				link: pagesLinks.restoGuide,
			},
			{
				icon: <BlogIcon className={styles.knowledgeBaseItem__icon} />,
				text: "Блог\nQuick Resto",
				link: pagesLinks.blog,
			},
			{
				icon: <WebinarIcon className={styles.knowledgeBaseItem__icon} />,
				text: "Обучающие\nвебинары",
				link: {
					href: externalLinks.webinars,
				},
			},
			{
				icon: <SupportIcon className={styles.knowledgeBaseItem__icon} />,
				text: "Раздел\nтехподдержки",
				link: pagesLinks.support,
			},
		],
	};
};
