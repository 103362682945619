import * as React from "react"

const RestoGuideIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 30.842h40M14.856 30.842a17.143 17.143 0 0 1 34.286 0"
			strokeWidth={3}
			strokeMiterlimit={10}
			strokeLinecap="round"
		/>
		<path
			d="M21.24 25.127c.36-1.02 1.526-2.744 2.517-3.405M31.998 13.7a2.857 2.857 0 1 0 0-5.715 2.857 2.857 0 0 0 0 5.714Z"
			strokeWidth={3}
			strokeMiterlimit={10}
			strokeLinecap="round"
		/>
		<path
			d="m21.595 40.444 14.203-2.225 6.691-6.682a2.372 2.372 0 0 1 3.354 3.354l-8.55 8.949-8.931 6.029-3.703 6.147-8.973-5.27 1.69-2.84"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="m29.455 39.147 6.354-7.61a2.372 2.372 0 0 1 3.354 3.354"
			strokeWidth={3}
			strokeLinejoin="round"
		/>
		<path
			d="m21.49 40.486 1.592-6.3a2.252 2.252 0 0 0-4.35-1.166l-2.434 9.086.005.002a6.057 6.057 0 0 0 1.14 5.735"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default RestoGuideIcon
